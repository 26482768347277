<template>
  <div class="auth-wrapper auth-v2" style="background-color: white">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img alt="logo" src="/mainLogo.png">
          <h2 class="brand-text text-primary ml-1">
            한국건설기술연구원
          </h2>
        </b-img>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col class="d-none d-lg-flex align-items-center p-5" lg="8">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img alt="Login V2" fluid src="/bgMain1.jpg" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- 레이어 팝업 -->
      <div v-if="showPopup" class="layer-popup">
        <div class="popup-content">
          <h1>🔒 광역검진 서비스 플랫폼 가입 안내</h1>
          <div class="notice">
            <p><span class="highlight">현재 개발 중인 비공개 서비스</span>로, 일반 가입 및 열람이 <strong>제한</strong>됩니다.</p>
          </div>
          <div style="text-align: left">
            <p>➡️ <strong>공개 범위:</strong> 개발팀 내부 및 승인된 공공 목적 사용자</p>
            <p>➡️ <strong>승인 절차:</strong> 공공 활용 목적을 포함한 사용 계획에 대한 간략한 메일 작성 신청</p>
            <p>➡️ <strong>기타 관심자:</strong> 연구원 방문 시 시스템 시연 및 설명 가능</p>
          </div>
          <div class="contact-info">
            <strong>문의 및 신청:</strong> kimhyegi@kict.re.kr<br />
            (공공 활용 목적 등을 작성하여 위 메일로 송부해 주세요)
          </div>
        </div>
      </div>

      <!-- Login-->
      <b-col class="d-flex align-items-center auth-bg px-2 p-lg-5" lg="4">
        <b-col class="px-xl-2 mx-auto" lg="12" md="6" sm="8">
          <b-card-title class="font-weight-bold mb-1" title-tag="h2">
            광역검진통합서비스 플랫폼
          </b-card-title>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="아이디(이메일)" label-for="login-userId">
                <validation-provider #default="{ errors }" name="userId" rules="required|email">
                  <b-form-input id="login-userId" v-model="formData.userId" :state="errors.length > 0 ? false : null" name="login-userId" placeholder="sample@kict.re.kr" />
                  <small v-if="errors.length > 0" class="text-danger">
                    이메일(아이디)은 필수입니다.
                  </small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">비밀번호</label>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group :class="errors.length > 0 ? 'is-invalid' : null" class="input-group-merge">
                    <b-form-input
                      id="login-password"
                      v-model="formData.password"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger">비밀번호 필수입니다.</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button block class="btn-login" type="submit" @click="validationForm">
                로그인
              </b-button>
              <b-button block to="/auth-join" type="submit" variant="primary">
                회원가입
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { BButton, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BImg, BInputGroup, BInputGroupAppend, BLink, BRow } from 'bootstrap-vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { email, required } from '@core/utils/validations/validations';
import router from '@/router';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      formData: {
        userId: '',
        password: '',
      },
      required,
      email,
      showPopup: true, // 팝업 상태 관리
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  async created() {
    await this.$store.dispatch('auth/FETCH_AUTH_LOGOUT');
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(async (success) => {
        if (success) {
          // 로그인
          const res = await this.$store.dispatch('auth/FETCH_AUTH_LOGIN', this.formData);
          if (res.resultFlag) {
            await router.push({ path: '/building-service' });
          } else {
            alert(res.resultMsg);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.layer-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 540px;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.popup-content {
  text-align: center;
}

.popup-close {
  margin-top: 20px;
}

.btn-login,
.btn-secondary:focus {
  color: #fff !important;
  background-color: #f49f17 !important;
  border-color: #f49f17 !important;
}

.highlight {
  background-color: #ffe066;
  padding: 2px 5px;
  border-radius: 3px;
}

.notice {
  background-color: #e6f3ff;
  border-left: 5px solid #0056b3;
  padding: 10px;
  margin-bottom: 15px;
}

.contact-info {
  background-color: #e6f3ff;
  border: 1px solid #0056b3;
  padding: 10px;
  margin-top: 15px;
  text-align: center;
}

.button {
  display: inline-block;
  background-color: #0056b3;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
